const ID_TOKEN_KEY = "wmt";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const saveRefreshToken = (RefreshToken) => {
  window.localStorage.setItem("RefreshToken", RefreshToken);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem("RefreshToken");
};

export const setUser = (user) => {
  window.localStorage.setItem("user", user);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem("user"));
};

export const destroyUser = () => {
  window.localStorage.removeItem("user");
};

export const destroyAccess = () => {
  window.localStorage.removeItem("access_right_display");
};

export const setConfiguration = (configuration) => {
  window.localStorage.setItem('configuration', configuration)
}

export const getConfiguration = () => {
  return JSON.parse(window.localStorage.getItem('configuration'))
}

export default { getToken, saveToken, destroyToken, saveRefreshToken, getRefreshToken, setUser, getUser, destroyUser, destroyAccess, setConfiguration, getConfiguration };

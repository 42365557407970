import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
        meta: {
          title: "Fisika UNJ - Dashboard",
          access_right: "1",
        },
      },
      {
        path: "/exam-registration",
        name: "exam-registration",
        component: () => import("@/view/pages/exam-registration/ExamRegistration.vue"),
        meta: {
          title: "Fisika UNJ - Pendaftaran Ujian",
        },
      },
      {
        path: "/classrooms",
        name: "classrooms",
        redirect: "/classrooms/list",
        component: () => import("@/view/pages/classroom/Classroom.vue"),
        meta: {
          title: "Fisika UNJ - Kelas Saya",
          access_right: "2",
        },
        children: [
          {
            path: "/classrooms/list",
            name: "classroom-list",
            component: () => import("@/view/pages/classroom/Classrooms.vue"),
            meta: {
              title: "Fisika UNJ - Kelas Saya",
              access_right: "1001",
            },
          },
          {
            path: "/classrooms/detail/:id",
            name: "classroom-detail",
            component: () => import("@/view/pages/classroom/ClassroomDetail.vue"),
            meta: {
              title: "Fisika UNJ - Detail Kelas Saya",
              access_right: "1001",
            },
          },
          {
            path: "/classrooms/add",
            name: "classroom-add",
            component: () => import("@/view/pages/classroom/ClassroomsAdd.vue"),
            meta: {
              title: "Fisika UNJ - Tambah Kelas",
              access_right: "1002",
            },
          },
          {
            path: "/classroom/join",
            name: "join-class",
            component: () => import("@/view/pages/classroom/JoinClass.vue"),
            meta: {
              title: "Fisika UNJ - Gabung Kelas",
              access_right: "1003",
            },
          },
          {
            path: "/classrooms/edit/:id",
            name: "classroom-edit",
            component: () => import("@/view/pages/classroom/ClassroomsUpdate.vue"),
            meta: {
              title: "Fisika UNJ - Edit Kelas",
              access_right: "1002",
            },
          },
        ],
      },
      // {
      //   path: "/classroom/join",
      //   name: "join-class",
      //   component: () => import("@/view/pages/classroom/JoinClass.vue"),
      //   meta:{
      //     title: 'Fisika UNJ - Gabung Kelas'
      //   }
      // },
      {
        path: "/bank",
        name: "bank",
        component: () => import("@/view/pages/bank-materi-soal/BankMateriSoal.vue"),
        meta: {
          title: "Fisika UNJ - Bank Materi dan Soal",
          access_right: "2012",
        },
      },
      {
        path: "/courses",
        name: "course",
        component: () => import("@/view/pages/courses/Course.vue"),
        meta: {
          title: "Fisika UNJ - Materi Pelajaran",
        },
      },
      {
        path: "/courses/add",
        name: "course-add",
        component: () => import("@/view/pages/courses/CourseAdd.vue"),
        meta: {
          title: "Fisika UNJ - Materi Pelajaran",
        },
      },
      {
        path: "/courses/edit/:id",
        name: "course-edit",
        component: () => import("@/view/pages/courses/CourseUpdate.vue"),
        meta: {
          title: "Fisika UNJ - Materi Pelajaran",
        },
      },
      {
        path: "/courses/detail/:id",
        name: "course-detail",
        component: () => import("@/view/pages/courses/CourseDetail.vue"),
        meta: {
          title: "Fisika UNJ - Materi Pelajaran",
        },
      },
      {
        path: "/agenda",
        name: "agenda",
        component: () => import("@/view/pages/Calendar.vue"),
        meta: {
          title: "Fisika UNJ - Agenda",
        },
      },

      // Personal Storage
      {
        path: "/personal-storage",
        name: "personal-storage",
        component: () => import("@/view/pages/personal-storage/PersonalStorage.vue"),
        meta: {
          title: "Fisika UNJ - File Personal",
          access_right: "3001",
        },
      },
      // Academic Guidance
      {
        path: "/academic-guidance",
        name: "academic-guidance",
        redirect: "/academic-guidance/list",
        component: () => import("@/view/pages/academic-guidance/AcademicGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan Akademik",
        },
        children: [
          {
            path: "/academic-guidance/list",
            name: "academic-guidance-list",
            component: () => import("@/view/pages/academic-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Akademik",
            },
          },
          {
            path: "/academic-guidance/add",
            name: "academic-guidance-add",
            component: () => import("@/view/pages/academic-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Akademik",
            },
          },
          {
            path: "/academic-guidance/edit/:id",
            name: "academic-guidance-edit",
            component: () => import("@/view/pages/academic-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Akademik",
            },
          },
        ],
      },
      // Field Practice Guidance
      {
        path: "/field-practice-guidance",
        name: "field-practice-guidance",
        redirect: "/field-practice-guidance/list",
        component: () => import("@/view/pages/field-practice-guidance/FieldPracticeGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan PKL",
        },
        children: [
          {
            path: "/field-practice-guidance/list",
            name: "field-practice-guidance-list",
            component: () => import("@/view/pages/field-practice-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKL",
            },
          },
          {
            path: "/field-practice-guidance/add",
            name: "field-practice-guidance-add",
            component: () => import("@/view/pages/field-practice-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKL",
            },
          },
          {
            path: "/field-practice-guidance/edit/:id",
            name: "field-practice-guidance-edit",
            component: () => import("@/view/pages/field-practice-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKL",
            },
          },
        ],
      },

      // Creativity Program Guidance
      {
        path: "/creativity-program-guidance",
        name: "creativity-program-guidance",
        redirect: "/creativity-program-guidance/list",
        component: () => import("@/view/pages/creativity-program-guidance/CreativityProgramGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan PKM",
        },
        children: [
          {
            path: "/creativity-program-guidance/list",
            name: "creativity-program-guidance-list",
            component: () => import("@/view/pages/creativity-program-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKM",
            },
          },
          {
            path: "/creativity-program-guidance/add",
            name: "creativity-program-guidance-add",
            component: () => import("@/view/pages/creativity-program-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKM",
            },
          },
          {
            path: "/creativity-program-guidance/edit/:id",
            name: "creativity-program-guidance-edit",
            component: () => import("@/view/pages/creativity-program-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan PKM",
            },
          },
        ],
      },
      // Essay Guidance
      {
        path: "/essay-guidance",
        name: "essay-guidance",
        redirect: "/essay-guidance/list",
        component: () => import("@/view/pages/essay-guidance/EssayGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan Skripsi",
        },
        children: [
          {
            path: "/essay-guidance/list",
            name: "essay-guidance-list",
            component: () => import("@/view/pages/essay-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Skripsi",
            },
          },
          {
            path: "/essay-guidance/add",
            name: "essay-guidance-add",
            component: () => import("@/view/pages/essay-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Skripsi",
            },
          },
          {
            path: "/essay-guidance/edit/:id",
            name: "essay-guidance-edit",
            component: () => import("@/view/pages/essay-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Skripsi",
            },
          },
        ],
      },
      // Thesis Guidance
      {
        path: "/thesis-guidance",
        name: "thesis-guidance",
        redirect: "/thesis-guidance/list",
        component: () => import("@/view/pages/thesis-guidance/ThesisGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan Tesis",
        },
        children: [
          {
            path: "/thesis-guidance/list",
            name: "thesis-guidance-list",
            component: () => import("@/view/pages/thesis-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Tesis",
            },
          },
          {
            path: "/thesis-guidance/add",
            name: "thesis-guidance-add",
            component: () => import("@/view/pages/thesis-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Tesis",
            },
          },
          {
            path: "/thesis-guidance/edit/:id",
            name: "thesis-guidance-edit",
            component: () => import("@/view/pages/thesis-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Tesis",
            },
          },
        ],
      },
      // Activity Guidance
      {
        path: "/activity-guidance",
        name: "activity-guidance",
        redirect: "/activity-guidance/list",
        component: () => import("@/view/pages/activity-guidance/ActivityGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan Kegiatan MBKM",
        },
        children: [
          {
            path: "/activity-guidance/list",
            name: "activity-guidance-list",
            component: () => import("@/view/pages/activity-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan MBKM",
            },
          },
          {
            path: "/activity-guidance/add",
            name: "activity-guidance-add",
            component: () => import("@/view/pages/activity-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan MBKM",
            },
          },
          {
            path: "/activity-guidance/edit/:id",
            name: "activity-guidance-edit",
            component: () => import("@/view/pages/activity-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan MBKM",
            },
          },
        ],
      },
      // Student Activity Guidance
      {
        path: "/student-activity-guidance",
        name: "student-activity-guidance",
        redirect: "/student-activity-guidance/list",
        component: () => import("@/view/pages/student-activity-guidance/StudentActivityGuidance.vue"),
        meta: {
          title: "Fisika UNJ | Bimbingan Kegiatan Kemahasiswaan",
        },
        children: [
          {
            path: "/student-activity-guidance/list",
            name: "student-activity-guidance-list",
            component: () => import("@/view/pages/student-activity-guidance/List.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan Kemahasiswaan",
            },
          },
          {
            path: "/student-activity-guidance/add",
            name: "student-activity-guidance-add",
            component: () => import("@/view/pages/student-activity-guidance/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan Kemahasiswaan",
            },
          },
          {
            path: "/student-activity-guidance/edit/:id",
            name: "student-activity-guidance-edit",
            component: () => import("@/view/pages/student-activity-guidance/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bimbingan Kegiatan Kemahasiswaan",
            },
          },
        ],
      },
      // Academic Guidance
      {
        path: "/print-request",
        name: "print-request",
        redirect: "/print-request/list",
        component: () => import("@/view/pages/print-request/PrintRequest.vue"),
        meta: {
          title: "Fisika UNJ | Pengajuan Cetak",
        },
        children: [
          {
            path: "/print-request/list",
            name: "print-request-list",
            component: () => import("@/view/pages/print-request/List.vue"),
            meta: {
              title: "Fisika UNJ | Pengajuan Cetak",
            },
          },
          {
            path: "/print-request/add",
            name: "print-request-add",
            component: () => import("@/view/pages/print-request/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pengajuan Cetak",
            },
          },
          {
            path: "/print-request/edit/:id",
            name: "print-request-edit",
            component: () => import("@/view/pages/print-request/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pengajuan Cetak",
            },
          },
          {
            path: "/print-request/detail/:id",
            name: "print-request-detail",
            component: () => import("@/view/pages/print-request/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pengajuan Cetak",
            },
          },
        ],
      },
      // presence
      {
        path: "/presence",
        name: "presence",
        redirect: "/presence/list",
        component: () => import("@/view/pages/presence/Presence.vue"),
        meta: {
          title: "Fisika UNJ | Kehadiran",
        },
        children: [
          {
            path: "/presence/list",
            name: "presence-list",
            component: () => import("@/view/pages/presence/PresenceList.vue"),
            meta: {
              title: "Fisika UNJ | Kehadiran",
            },
          },
          {
            path: "/presence/edit/:id",
            name: "presence-edit",
            component: () => import("@/view/pages/presence/PresenceUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Kehadiran",
            },
          },
          {
            path: "/presence/detail/:id",
            name: "presence-detail",
            component: () => import("@/view/pages/presence/PresenceDetail.vue"),
            meta: {
              title: "Fisika UNJ | Kehadiran",
            },
          },
        ],
      },
      {
        path: "/school-report-card",
        name: "school-report-card",
        redirect: "/school-report-card/list",
        component: () => import("@/view/pages/report/school-report-card/SchoolReportCard.vue"),
        meta: {
          title: "Fisika UNJ | Rapot Online",
        },
        children: [
          {
            path: "/school-report-card/list",
            name: "school-report-card-list",
            component: () => import("@/view/pages/report/school-report-card/SchoolReportCardList.vue"),
            meta: {
              title: "Fisika UNJ | Rapot Online",
            },
          },
          {
            path: "/school-report-card/detail/assignment/:classwork_id/:classwork_type_id/:student_id",
            name: "school-report-card-detail-assignment",
            component: () => import("@/view/pages/report/school-report-card/SchoolReportCardAssignmentDetail.vue"),
            meta: {
              title: "Fisika UNJ | Rapot Online",
            },
          },
        ],
      },
      {
        path: "/message-box",
        name: "message-box",
        redirect: "/message-box/messages/:user_id/:message_to",
        component: () => import("@/view/pages/chats/Chat.vue"),
        meta: {
          title: "Fisika UNJ | Kotak Pesan",
        },
        children: [
          // setting
          {
            path: "/message-box/messages/:user_id/:message_to",
            name: "message-box-message",
            component: () => import("@/view/pages/chats/Conversation.vue"),
            meta: {
              title: "Fisika UNJ | Kotak Pesan",
              access_right: "5001",
            },
          },
        ],
      },
      {
        path: "/academic-calendars",
        name: "academic-calendars",
        redirect: "/academic-calendars/list",
        component: () => import("@/view/pages/chats/Chat.vue"),
        meta: {
          title: "Fisika UNJ | Kalender Akademi",
        },
        children: [
          {
            path: "/academic-calendars/list",
            name: "academic-calendars-list",
            component: () => import("@/view/pages/academic-calendars/List.vue"),
            meta: {
              title: "Fisika UNJ | Kalender Akademi",
              access_right: "8007",
            },
          },
          {
            path: "/academic-calendars/add",
            name: "academic-calendars-add",
            component: () => import("@/view/pages/academic-calendars/AcademicCalendarAdd.vue"),
            meta: {
              title: "Fisika UNJ | Kalender Akademi",
              access_right: "8008",
            },
          },
          {
            path: "/academic-calendars/add-holidays",
            name: "academic-calendars-add-holidays",
            component: () => import("@/view/pages/academic-calendars/AcademicCalendarAddHoliday.vue"),
            meta: {
              title: "Fisika UNJ | Kalender Akademi",
              access_right: "8008",
            },
          },
          {
            path: "/academic-calendars/edit/:id",
            name: "academic-calendars-edit",
            component: () => import("@/view/pages/academic-calendars/AcademicCalendarUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Kalender Akademi",
              access_right: "8008",
            },
          },
        ],
      },
      {
        path: "/academic-calendars-year",
        name: "academic-calendars-year",
        redirect: "/academic-calendars-year/list",
        component: () => import("@/view/pages/chats/Chat.vue"),
        meta: {
          title: "Fisika UNJ | Kalender Akademik",
        },
        children: [
          {
            path: "/academic-calendars-year/list",
            name: "academic-calendars-year-list",
            component: () => import("@/view/pages/academic-calendars/AcademicCalendarYear.vue"),
            meta: {
              title: "Fisika UNJ | Kalender Akademik",
              access_right: "8007",
            },
          },
        ],
      },

      /**
       * Laboratory
       */
      // tool-categories
      {
        path: "/tool-categories",
        name: "tool-categories",
        redirect: "/tool-categories/list",
        component: () => import("@/view/pages/masters/tool-categories/ToolCategory.vue"),
        meta: {
          title: "Fisika UNJ | Kategori Alat",
        },
        children: [
          {
            path: "/tool-categories/list",
            name: "tool-categories-list",
            component: () => import("@/view/pages/masters/tool-categories/List.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Alat",
            },
          },
          {
            path: "/tool-categories/add",
            name: "tool-categories-add",
            component: () => import("@/view/pages/masters/tool-categories/Add.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Alat",
            },
          },
          {
            path: "/tool-categories/edit/:id",
            name: "tool-categories-edit",
            component: () => import("@/view/pages/masters/tool-categories/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Alat",
            },
          },
        ],
      },
      // tools
      {
        path: "/tools",
        name: "tools",
        redirect: "/tools/list",
        component: () => import("@/view/pages/tools/Tool.vue"),
        meta: {
          title: "Fisika UNJ | Alat",
        },
        children: [
          {
            path: "/tools/list",
            name: "tools-list",
            component: () => import("@/view/pages/tools/List.vue"),
            meta: {
              title: "Fisika UNJ | Alat",
            },
          },
          {
            path: "/tools/add",
            name: "tools-add",
            component: () => import("@/view/pages/tools/Add.vue"),
            meta: {
              title: "Fisika UNJ | Alat",
            },
          },
          {
            path: "/tools/edit/:id",
            name: "tools-edit",
            component: () => import("@/view/pages/tools/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Alat",
            },
          },
          {
            path: "/tools/detail/:id",
            name: "tools-detail",
            component: () => import("@/view/pages/tools/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Alat",
            },
          },
          {
            path: "/tools/duplicate/:id",
            name: "tools-duplicate",
            component: () => import("@/view/pages/tools/Duplicate.vue"),
            meta: {
              title: "Fisika UNJ | Alat",
            },
          },
        ],
      },
      // tool-borrowers
      {
        path: "/tool-borrowers",
        name: "tool-borrowers",
        redirect: "/tool-borrowers/list",
        component: () => import("@/view/pages/tool-borrowers/ToolBorrower.vue"),
        meta: {
          title: "Fisika UNJ | Peminjaman Alat",
        },
        children: [
          {
            path: "/tool-borrowers/list",
            name: "tool-borrowers-list",
            component: () => import("@/view/pages/tool-borrowers/List.vue"),
            meta: {
              title: "Fisika UNJ | Peminjaman Alat",
            },
          },
          {
            path: "/tool-borrowers/add",
            name: "tool-borrowers-add",
            component: () => import("@/view/pages/tool-borrowers/Add.vue"),
            meta: {
              title: "Fisika UNJ | Peminjaman Alat",
            },
          },
          {
            path: "/tool-borrowers/edit/:id",
            name: "tool-borrowers-edit",
            component: () => import("@/view/pages/tool-borrowers/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Peminjaman Alat",
            },
          },
          {
            path: "/tool-borrowers/detail/:id",
            name: "tool-borrowers-detail",
            component: () => import("@/view/pages/tool-borrowers/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Peminjaman Alat",
            },
          },
        ],
      },
      // lab-material-categories
      {
        path: "/lab-material-categories",
        name: "lab-material-categories",
        redirect: "/lab-material-categories/list",
        component: () => import("@/view/pages/masters/lab-material-categories/LabMaterialCategory.vue"),
        meta: {
          title: "Fisika UNJ | Kategori Bahan",
        },
        children: [
          {
            path: "/lab-material-categories/list",
            name: "lab-material-categories-list",
            component: () => import("@/view/pages/masters/lab-material-categories/List.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Bahan",
            },
          },
          {
            path: "/lab-material-categories/add",
            name: "lab-material-categories-add",
            component: () => import("@/view/pages/masters/lab-material-categories/Add.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Bahan",
            },
          },
          {
            path: "/lab-material-categories/edit/:id",
            name: "lab-material-categories-edit",
            component: () => import("@/view/pages/masters/lab-material-categories/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Kategori Bahan",
            },
          },
        ],
      },
      // lab-materials
      {
        path: "/lab-materials",
        name: "lab-materials",
        redirect: "/lab-materials/list",
        component: () => import("@/view/pages/lab-materials/LabMaterial.vue"),
        meta: {
          title: "Fisika UNJ | Bahan",
        },
        children: [
          {
            path: "/lab-materials/list",
            name: "lab-materials-list",
            component: () => import("@/view/pages/lab-materials/List.vue"),
            meta: {
              title: "Fisika UNJ | Bahan",
            },
          },
          {
            path: "/lab-materials/add",
            name: "lab-materials-add",
            component: () => import("@/view/pages/lab-materials/Add.vue"),
            meta: {
              title: "Fisika UNJ | Bahan",
            },
          },
          {
            path: "/lab-materials/edit/:id",
            name: "lab-materials-edit",
            component: () => import("@/view/pages/lab-materials/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Bahan",
            },
          },
          {
            path: "/lab-materials/detail/:id",
            name: "lab-materials-detail",
            component: () => import("@/view/pages/lab-materials/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Bahan",
            },
          },
        ],
      },
      // lab-material-stocks
      {
        path: "/lab-material-stocks",
        name: "lab-material-stocks",
        redirect: "/lab-material-stocks/list",
        component: () => import("@/view/pages/lab-material-stocks/LabMaterialStock.vue"),
        meta: {
          title: "Fisika UNJ | Stok Bahan",
        },
        children: [
          {
            path: "/lab-material-stocks/list",
            name: "lab-material-stocks-list",
            component: () => import("@/view/pages/lab-material-stocks/List.vue"),
            meta: {
              title: "Fisika UNJ | Stok Bahan",
            },
          },
          {
            path: "/lab-material-stocks/add",
            name: "lab-material-stocks-add",
            component: () => import("@/view/pages/lab-material-stocks/Add.vue"),
            meta: {
              title: "Fisika UNJ | Stok Bahan",
            },
          },
          // {
          //   path: "/lab-material-stocks/edit/:id",
          //   name: "lab-material-stocks-edit",
          //   component: () => import("@/view/pages/lab-material-stocks/Edit.vue"),
          //   meta: {
          //     title: 'Fisika UNJ | Stok Bahan'
          //   },
          // },
          {
            path: "/lab-material-stocks/detail/:id",
            name: "lab-material-stocks-detail",
            component: () => import("@/view/pages/lab-material-stocks/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Stok Bahan",
            },
          },
        ],
      },
      // log-books
      {
        path: "/log-books",
        name: "log-books",
        redirect: "/log-books/list",
        component: () => import("@/view/pages/log-books/LogBook.vue"),
        meta: {
          title: "Fisika UNJ | Catatan Praktikum dan Penelitian",
        },
        children: [
          {
            path: "/log-books/list",
            name: "log-books-list",
            component: () => import("@/view/pages/log-books/List.vue"),
            meta: {
              title: "Fisika UNJ | Catatan Praktikum dan Penelitian",
            },
          },
          {
            path: "/log-books/add",
            name: "log-books-add",
            component: () => import("@/view/pages/log-books/Add.vue"),
            meta: {
              title: "Fisika UNJ | Catatan Praktikum dan Penelitian",
            },
          },
          {
            path: "/log-books/edit/:id",
            name: "log-books-edit",
            component: () => import("@/view/pages/log-books/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Catatan Praktikum dan Penelitian",
            },
          },
          {
            path: "/log-books/detail/:id",
            name: "log-books-detail",
            component: () => import("@/view/pages/log-books/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Catatan Praktikum dan Penelitian",
            },
          },
        ],
      },
      // practicum-permit
      {
        path: "/practicum-permit",
        name: "practicum-permit",
        redirect: "/practicum-permit/list",
        component: () => import("@/view/pages/practicum-permit/PracticumPermit.vue"),
        meta: {
          title: "Fisika UNJ | Surat Izin Praktikum",
        },
        children: [
          {
            path: "/practicum-permit/list",
            name: "practicum-permit-list",
            component: () => import("@/view/pages/practicum-permit/List.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Praktikum",
            },
          },
          {
            path: "/practicum-permit/add",
            name: "practicum-permit-add",
            component: () => import("@/view/pages/practicum-permit/Add.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Praktikum",
            },
          },
          {
            path: "/practicum-permit/edit/:id",
            name: "practicum-permit-edit",
            component: () => import("@/view/pages/practicum-permit/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Praktikum",
            },
          },
          {
            path: "/practicum-permit/detail/:id",
            name: "practicum-permit-detail",
            component: () => import("@/view/pages/practicum-permit/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Praktikum",
            },
          },
        ],
      },
      // thesis-research-permit
      {
        path: "/thesis-research-permit",
        name: "thesis-research-permit",
        redirect: "/thesis-research-permit/list",
        component: () => import("@/view/pages/thesis-research-permit/ThesisResearchPermit.vue"),
        meta: {
          title: "Fisika UNJ | Surat Izin Penelitian Skripsi/Tesis",
        },
        children: [
          {
            path: "/thesis-research-permit/list",
            name: "thesis-research-permit-list",
            component: () => import("@/view/pages/thesis-research-permit/List.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Penelitian Skripsi/Tesis",
            },
          },
          {
            path: "/thesis-research-permit/add",
            name: "thesis-research-permit-add",
            component: () => import("@/view/pages/thesis-research-permit/Add.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Penelitian Skripsi/Tesis",
            },
          },
          {
            path: "/thesis-research-permit/edit/:id",
            name: "thesis-research-permit-edit",
            component: () => import("@/view/pages/thesis-research-permit/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Penelitian Skripsi/Tesis",
            },
          },
          {
            path: "/thesis-research-permit/detail/:id",
            name: "thesis-research-permit-detail",
            component: () => import("@/view/pages/thesis-research-permit/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Surat Izin Penelitian Skripsi/Tesis",
            },
          },
        ],
      },
      // sem-registration
      {
        path: "/sem-registration",
        name: "sem-registration",
        redirect: "/sem-registration/list",
        component: () => import("@/view/pages/sem-registration/SemRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Karakterisasi SEM",
        },
        children: [
          {
            path: "/sem-registration/list",
            name: "sem-registration-list",
            component: () => import("@/view/pages/sem-registration/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Karakterisasi SEM",
            },
          },
          {
            path: "/sem-registration/add",
            name: "sem-registration-add",
            component: () => import("@/view/pages/sem-registration/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Karakterisasi SEM",
            },
          },
          {
            path: "/sem-registration/edit/:id",
            name: "sem-registration-edit",
            component: () => import("@/view/pages/sem-registration/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Karakterisasi SEM",
            },
          },
          {
            path: "/sem-registration/detail/:id",
            name: "sem-registration-detail",
            component: () => import("@/view/pages/sem-registration/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Karakterisasi SEM",
            },
          },
        ],
      },
      // schedules
      {
        path: "/schedules",
        name: "schedules",
        redirect: "/schedules/list",
        component: () => import("@/view/pages/schedules/Schedule.vue"),
        meta: {
          title: "Fisika UNJ | Jadwal & Kurikulum",
        },
        children: [
          {
            path: "/schedules/list",
            name: "schedules-list",
            component: () => import("@/view/pages/schedules/List.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/add",
            name: "schedules-add",
            component: () => import("@/view/pages/schedules/Add.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/edit/:id",
            name: "schedules-edit",
            component: () => import("@/view/pages/schedules/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/detail/:id",
            name: "schedules-detail",
            component: () => import("@/view/pages/schedules/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/teacher",
            name: "schedules-teacher",
            component: () => import("@/view/pages/schedules/TeacherSchedule.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/table",
            name: "schedules-table",
            component: () => import("@/view/pages/schedules/Table.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
          {
            path: "/schedules/distribution",
            name: "schedules-distribution",
            component: () => import("@/view/pages/schedules/Distribution.vue"),
            meta: {
              title: "Fisika UNJ | Jadwal & Kurikulum",
            },
          },
        ],
      },
      // work-practice-exam-registrations
      {
        path: "/work-practice-exam-registrations",
        name: "work-practice-exam-registrations",
        redirect: "/work-practice-exam-registrations/list",
        component: () => import("@/view/pages/work-practice-exam-registrations/WorkPracticeExamRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Ujian PKL",
        },
        children: [
          {
            path: "/work-practice-exam-registrations/list",
            name: "work-practice-exam-registrations-list",
            component: () => import("@/view/pages/work-practice-exam-registrations/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Ujian PKL",
            },
          },
          {
            path: "/work-practice-exam-registrations/add",
            name: "work-practice-exam-registrations-add",
            component: () => import("@/view/pages/work-practice-exam-registrations/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Ujian PKL",
            },
          },
          {
            path: "/work-practice-exam-registrations/edit/:id",
            name: "work-practice-exam-registrations-edit",
            component: () => import("@/view/pages/work-practice-exam-registrations/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Ujian PKL",
            },
          },
          {
            path: "/work-practice-exam-registrations/detail/:id",
            name: "work-practice-exam-registrations-detail",
            component: () => import("@/view/pages/work-practice-exam-registrations/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Ujian PKL",
            },
          },
        ],
      },

      // pra-essay-seminar-registrations
      {
        path: "/pra-essay-seminar-registrations",
        name: "pra-essay-seminar-registrations",
        redirect: "/pra-essay-seminar-registrations/list",
        component: () => import("@/view/pages/pra-essay-seminar-registrations/PraEssaySeminarRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Seminar Pra Skripsi",
        },
        children: [
          {
            path: "/pra-essay-seminar-registrations/list",
            name: "pra-essay-seminar-registrations-list",
            component: () => import("@/view/pages/pra-essay-seminar-registrations/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Skripsi",
            },
          },
          {
            path: "/pra-essay-seminar-registrations/add",
            name: "pra-essay-seminar-registrations-add",
            component: () => import("@/view/pages/pra-essay-seminar-registrations/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Skripsi",
            },
          },
          {
            path: "/pra-essay-seminar-registrations/edit/:id",
            name: "pra-essay-seminar-registrations-edit",
            component: () => import("@/view/pages/pra-essay-seminar-registrations/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Skripsi",
            },
          },
          {
            path: "/pra-essay-seminar-registrations/detail/:id",
            name: "pra-essay-seminar-registrations-detail",
            component: () => import("@/view/pages/pra-essay-seminar-registrations/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Skripsi",
            },
          },
        ],
      },

      // essay-exam-registrations
      {
        path: "/essay-exam-registrations",
        name: "essay-exam-registrations",
        redirect: "/essay-exam-registrations/list",
        component: () => import("@/view/pages/essay-exam-registrations/EssayExamRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
        },
        children: [
          {
            path: "/essay-exam-registrations/list",
            name: "essay-exam-registrations-list",
            component: () => import("@/view/pages/essay-exam-registrations/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/essay-exam-registrations/add",
            name: "essay-exam-registrations-add",
            component: () => import("@/view/pages/essay-exam-registrations/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/essay-exam-registrations/create/:id",
            name: "essay-exam-registrations-create",
            component: () => import("@/view/pages/essay-exam-registrations/Create.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/essay-exam-registrations/edit/:id",
            name: "essay-exam-registrations-edit",
            component: () => import("@/view/pages/essay-exam-registrations/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/essay-exam-registrations/detail/:id",
            name: "essay-exam-registrations-detail",
            component: () => import("@/view/pages/essay-exam-registrations/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
        ],
      },

      // pra-thesis-seminar-registrations
      {
        path: "/pra-thesis-seminar-registrations",
        name: "pra-thesis-seminar-registrations",
        redirect: "/pra-thesis-seminar-registrations/list",
        component: () => import("@/view/pages/pra-thesis-seminar-registrations/PraThesisSeminarRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Seminar Pra Tesis",
        },
        children: [
          {
            path: "/pra-thesis-seminar-registrations/list",
            name: "pra-thesis-seminar-registrations-list",
            component: () => import("@/view/pages/pra-thesis-seminar-registrations/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Tesis",
            },
          },
          {
            path: "/pra-thesis-seminar-registrations/add",
            name: "pra-thesis-seminar-registrations-add",
            component: () => import("@/view/pages/pra-thesis-seminar-registrations/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Tesis",
            },
          },
          {
            path: "/pra-thesis-seminar-registrations/edit/:id",
            name: "pra-thesis-seminar-registrations-edit",
            component: () => import("@/view/pages/pra-thesis-seminar-registrations/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Tesis",
            },
          },
          {
            path: "/pra-thesis-seminar-registrations/detail/:id",
            name: "pra-thesis-seminar-registrations-detail",
            component: () => import("@/view/pages/pra-thesis-seminar-registrations/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Seminar Pra Tesis",
            },
          },
        ],
      },

      // thesis-exam-registrations
      {
        path: "/thesis-exam-registrations",
        name: "thesis-exam-registrations",
        redirect: "/thesis-exam-registrations/list",
        component: () => import("@/view/pages/thesis-exam-registrations/ThesisExamRegistration.vue"),
        meta: {
          title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
        },
        children: [
          {
            path: "/thesis-exam-registrations/list",
            name: "thesis-exam-registrations-list",
            component: () => import("@/view/pages/thesis-exam-registrations/List.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/thesis-exam-registrations/add",
            name: "thesis-exam-registrations-add",
            component: () => import("@/view/pages/thesis-exam-registrations/Add.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/thesis-exam-registrations/edit/:id",
            name: "thesis-exam-registrations-edit",
            component: () => import("@/view/pages/thesis-exam-registrations/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
          {
            path: "/thesis-exam-registrations/detail/:id",
            name: "thesis-exam-registrations-detail",
            component: () => import("@/view/pages/thesis-exam-registrations/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Pendaftaran Sidang Skripsi",
            },
          },
        ],
      },

      // ::letters::
      {
        path: "/letters",
        name: "letters",
        redirect: "/letters/field-practice",
        component: () => import("@/view/pages/letters/Letter.vue"),
        meta: {
          title: "Fisika UNJ | Administrasi Umum",
        },
        children: [
          // field-practice
          {
            path: "/letters/field-practice",
            name: "field-practice",
            redirect: "/letters/field-practice/list",
            component: () => import("@/view/pages/letters/field-practice/FieldPractice.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
            },
            children: [
              {
                path: "/letters/field-practice/list",
                name: "field-practice-list",
                component: () => import("@/view/pages/letters/field-practice/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/field-practice/add",
                name: "field-practice-add",
                component: () => import("@/view/pages/letters/field-practice/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/field-practice/edit/:id",
                name: "field-practice-edit",
                component: () => import("@/view/pages/letters/field-practice/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/field-practice/detail/:id",
                name: "field-practice-detail",
                component: () => import("@/view/pages/letters/field-practice/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
            ],
          },

          //need-analysis-form
          {
            path: "/letters/need-analysis-form",
            name: "need-analysis-form",
            redirect: "/letters/need-analysis-form/list",
            component: () => import("@/view/pages/letters/need-analysis-form/NeedAnalysisForm.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
            },
            children: [
              {
                path: "/letters/need-analysis-form/list",
                name: "need-analysis-form-list",
                component: () => import("@/view/pages/letters/need-analysis-form/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/need-analysis-form/add",
                name: "need-analysis-form-add",
                component: () => import("@/view/pages/letters/need-analysis-form/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/need-analysis-form/edit/:id",
                name: "need-analysis-form-edit",
                component: () => import("@/view/pages/letters/need-analysis-form/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
              {
                path: "/letters/need-analysis-form/detail/:id",
                name: "need-analysis-form-detail",
                component: () => import("@/view/pages/letters/need-analysis-form/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengantar PKL",
                },
              },
            ],
          },

          //pkm-application-form
          {
            path: "/letters/pkm-application-form",
            name: "pkm-application-form",
            redirect: "/letters/pkm-application-form/list",
            component: () => import("@/view/pages/letters/pkm-application-form/PkmApplicationForm.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
            },
            children: [
              {
                path: "/letters/pkm-application-form/list",
                name: "pkm-application-form-list",
                component: () => import("@/view/pages/letters/pkm-application-form/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/pkm-application-form/add",
                name: "pkm-application-form-add",
                component: () => import("@/view/pages/letters/pkm-application-form/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/pkm-application-form/edit/:id",
                name: "pkm-application-form-edit",
                component: () => import("@/view/pages/letters/pkm-application-form/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/pkm-application-form/detail/:id",
                name: "pkm-application-form-detail",
                component: () => import("@/view/pages/letters/pkm-application-form/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
            ],
          },

          //validation-approval-form
          {
            path: "/letters/validation-approval-form",
            name: "validation-approval-form",
            redirect: "/letters/validation-approval-form/list",
            component: () => import("@/view/pages/letters/validation-approval-form/ValidationApprovalForm.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
            },
            children: [
              {
                path: "/letters/validation-approval-form/list",
                name: "validation-approval-form-list",
                component: () => import("@/view/pages/letters/validation-approval-form/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/validation-approval-form/add",
                name: "validation-approval-form-add",
                component: () => import("@/view/pages/letters/validation-approval-form/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/validation-approval-form/edit/:id",
                name: "validation-approval-form-edit",
                component: () => import("@/view/pages/letters/validation-approval-form/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
              {
                path: "/letters/validation-approval-form/detail/:id",
                name: "validation-approval-form-detail",
                component: () => import("@/view/pages/letters/validation-approval-form/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
            ],
          },

          //validation-request-form
          {
            path: "/letters/validation-request-form",
            name: "validation-request-form",
            redirect: "/letters/validation-request-form/list",
            component: () => import("@/view/pages/letters/validation-request-form/ValidationRequestForm.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Permohonan Validasi ",
            },
            children: [
              {
                path: "/letters/validation-request-form/list",
                name: "validation-request-form-list",
                component: () => import("@/view/pages/letters/validation-request-form/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Permohonan Validasi ",
                },
              },
              {
                path: "/letters/validation-request-form/add",
                name: "validation-request-form-add",
                component: () => import("@/view/pages/letters/validation-request-form/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Permohonan Validasi ",
                },
              },
              {
                path: "/letters/validation-request-form/edit/:id",
                name: "validation-request-form-edit",
                component: () => import("@/view/pages/letters/validation-request-form/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Permohonan Validasi ",
                },
              },
              {
                path: "/letters/validation-request-form/detail/:id",
                name: "validation-request-form-detail",
                component: () => import("@/view/pages/letters/validation-request-form/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Praktek Keterampilan Mengajar",
                },
              },
            ],
          },

          // activity-guidance-teacher
          {
            path: "/letters/activity-guidance-teacher",
            name: "activity-guidance-teacher",
            redirect: "/letters/activity-guidance-teacher/list",
            component: () => import("@/view/pages/letters/activity-guidance-teacher/ActivityGuidanceTeacher.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Dosen Pembimbing Kegiatan",
            },
            children: [
              {
                path: "/letters/activity-guidance-teacher/list",
                name: "activity-guidance-teacher-list",
                component: () => import("@/view/pages/letters/activity-guidance-teacher/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Dosen Pembimbing Kegiatan",
                },
              },
              {
                path: "/letters/activity-guidance-teacher/add",
                name: "activity-guidance-teacher-add",
                component: () => import("@/view/pages/letters/activity-guidance-teacher/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Dosen Pembimbing Kegiatan",
                },
              },
              {
                path: "/letters/activity-guidance-teacher/edit/:id",
                name: "activity-guidance-teacher-edit",
                component: () => import("@/view/pages/letters/activity-guidance-teacher/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Dosen Pembimbing Kegiatan",
                },
              },
              {
                path: "/letters/activity-guidance-teacher/detail/:id",
                name: "activity-guidance-teacher-detail",
                component: () => import("@/view/pages/letters/activity-guidance-teacher/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Dosen Pembimbing Kegiatan",
                },
              },
            ],
          },

          // college-leave-application
          {
            path: "/letters/college-leave-application",
            name: "college-leave-application",
            redirect: "/letters/college-leave-application/list",
            component: () => import("@/view/pages/letters/college-leave-application/CollageLeaveApplication.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Cuti Kuliah",
            },
            children: [
              {
                path: "/letters/college-leave-application/list",
                name: "college-leave-application-list",
                component: () => import("@/view/pages/letters/college-leave-application/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Cuti Kuliah",
                },
              },
              {
                path: "/letters/college-leave-application/add",
                name: "college-leave-application-add",
                component: () => import("@/view/pages/letters/college-leave-application/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Cuti Kuliah",
                },
              },
              {
                path: "/letters/college-leave-application/edit/:id",
                name: "college-leave-application-edit",
                component: () => import("@/view/pages/letters/college-leave-application/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Cuti Kuliah",
                },
              },
              {
                path: "/letters/college-leave-application/detail/:id",
                name: "college-leave-application-detail",
                component: () => import("@/view/pages/letters/college-leave-application/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Cuti Kuliah",
                },
              },
            ],
          },

          // college-transfer-application
          {
            path: "/letters/college-transfer-application",
            name: "college-transfer-application",
            redirect: "/letters/college-transfer-application/list",
            component: () => import("@/view/pages/letters/college-transfer-application/CollegeTransferApplication.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pindah Kuliah",
            },
            children: [
              {
                path: "/letters/college-transfer-application/list",
                name: "college-transfer-application-list",
                component: () => import("@/view/pages/letters/college-transfer-application/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pindah Kuliah",
                },
              },
              {
                path: "/letters/college-transfer-application/add",
                name: "college-transfer-application-add",
                component: () => import("@/view/pages/letters/college-transfer-application/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pindah Kuliah",
                },
              },
              {
                path: "/letters/college-transfer-application/edit/:id",
                name: "college-transfer-application-edit",
                component: () => import("@/view/pages/letters/college-transfer-application/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pindah Kuliah",
                },
              },
              {
                path: "/letters/college-transfer-application/detail/:id",
                name: "college-transfer-application-detail",
                component: () => import("@/view/pages/letters/college-transfer-application/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pindah Kuliah",
                },
              },
            ],
          },

          // sample-research-outside
          {
            path: "/letters/sample-research-outside",
            name: "sample-research-outside",
            redirect: "/letters/sample-research-outside/list",
            component: () => import("@/view/pages/letters/sample-research-outside/SampleResearchOutside.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
            },
            children: [
              {
                path: "/letters/sample-research-outside/list",
                name: "sample-research-outside-list",
                component: () => import("@/view/pages/letters/sample-research-outside/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/sample-research-outside/add",
                name: "sample-research-outside-add",
                component: () => import("@/view/pages/letters/sample-research-outside/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/sample-research-outside/edit/:id",
                name: "sample-research-outside-edit",
                component: () => import("@/view/pages/letters/sample-research-outside/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/sample-research-outside/detail/:id",
                name: "sample-research-outside-detail",
                component: () => import("@/view/pages/letters/sample-research-outside/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Uji Sampel Penelitian di Luar Prodi",
                },
              },
            ],
          },
          // research-outside
          {
            path: "/letters/research-outside",
            name: "research-outside",
            redirect: "/letters/research-outside/list",
            component: () => import("@/view/pages/letters/research-outside/ResearchOutside.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Penelitian di Luar Prodi",
            },
            children: [
              {
                path: "/letters/research-outside/list",
                name: "research-outside-list",
                component: () => import("@/view/pages/letters/research-outside/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/research-outside/add",
                name: "research-outside-add",
                component: () => import("@/view/pages/letters/research-outside/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/research-outside/edit/:id",
                name: "research-outside-edit",
                component: () => import("@/view/pages/letters/research-outside/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Penelitian di Luar Prodi",
                },
              },
              {
                path: "/letters/research-outside/detail/:id",
                name: "research-outside-detail",
                component: () => import("@/view/pages/letters/research-outside/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Penelitian di Luar Prodi",
                },
              },
            ],
          },
          // nonlab-tool-loan
          {
            path: "/letters/nonlab-tool-loan",
            name: "nonlab-tool-loan",
            redirect: "/letters/nonlab-tool-loan/list",
            component: () => import("@/view/pages/letters/nonlab-tool-loan/NonlabToolLoan.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Non-Laboratorium",
            },
            children: [
              {
                path: "/letters/nonlab-tool-loan/list",
                name: "nonlab-tool-loan-list",
                component: () => import("@/view/pages/letters/nonlab-tool-loan/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Non-Laboratorium",
                },
              },
              {
                path: "/letters/nonlab-tool-loan/add",
                name: "nonlab-tool-loan-add",
                component: () => import("@/view/pages/letters/nonlab-tool-loan/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Non-Laboratorium",
                },
              },
              {
                path: "/letters/nonlab-tool-loan/edit/:id",
                name: "nonlab-tool-loan-edit",
                component: () => import("@/view/pages/letters/nonlab-tool-loan/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Non-Laboratorium",
                },
              },
              {
                path: "/letters/nonlab-tool-loan/detail/:id",
                name: "nonlab-tool-loan-detail",
                component: () => import("@/view/pages/letters/nonlab-tool-loan/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Non-Laboratorium",
                },
              },
            ],
          },
          // observation-outside
          {
            path: "/letters/observation-outside",
            name: "observation-outside",
            redirect: "/letters/observation-outside/list",
            component: () => import("@/view/pages/letters/observation-outside/ObservationOutside.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Observasi diluar Prodi",
            },
            children: [
              {
                path: "/letters/observation-outside/list",
                name: "observation-outside-list",
                component: () => import("@/view/pages/letters/observation-outside/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Observasi diluar Prodi",
                },
              },
              {
                path: "/letters/observation-outside/add",
                name: "observation-outside-add",
                component: () => import("@/view/pages/letters/observation-outside/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Observasi diluar Prodi",
                },
              },
              {
                path: "/letters/observation-outside/edit/:id",
                name: "observation-outside-edit",
                component: () => import("@/view/pages/letters/observation-outside/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Observasi diluar Prodi",
                },
              },
              {
                path: "/letters/observation-outside/detail/:id",
                name: "observation-outside-detail",
                component: () => import("@/view/pages/letters/observation-outside/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Observasi diluar Prodi",
                },
              },
            ],
          },
          // student-information
          {
            path: "/letters/student-information",
            name: "student-information",
            redirect: "/letters/student-information/list",
            component: () => import("@/view/pages/letters/student-information/StudentInformation.vue"),
            meta: {
              title: 'Fisika UNJ | Surat Keterangan Mahasiswa'
            },
            children: [
              {
                path: "/letters/student-information/list",
                name: "student-information-list",
                component: () => import("@/view/pages/letters/student-information/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Mahasiswa"
                }
              },
              {
                path: "/letters/student-information/add",
                name: "student-information-add",
                component: () => import("@/view/pages/letters/student-information/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Mahasiswa"
                }
              },
              {
                path: "/letters/student-information/edit/:id",
                name: "student-information-edit",
                component: () => import("@/view/pages/letters/student-information/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Mahasiswa"
                }
              },
              {
                path: "/letters/student-information/detail/:id",
                name: "student-information-detail",
                component: () => import("@/view/pages/letters/student-information/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Mahasiswa"
                }
              },
            ]
          },
          // scholarship-information
          {
            path: "/letters/scholarship-information",
            name: "scholarship-information",
            redirect: "/letters/scholarship-information/list",
            component: () => import("@/view/pages/letters/scholarship-information/ScholarshipInformation.vue"),
            meta: {
              title: 'Fisika UNJ | Surat Keterangan Beasiswa'
            },
            children: [
              {
                path: "/letters/scholarship-information/list",
                name: "scholarship-information-list",
                component: () => import("@/view/pages/letters/scholarship-information/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Beasiswa"
                }
              },
              {
                path: "/letters/scholarship-information/add",
                name: "scholarship-information-add",
                component: () => import("@/view/pages/letters/scholarship-information/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Beasiswa"
                }
              },
              {
                path: "/letters/scholarship-information/edit/:id",
                name: "scholarship-information-edit",
                component: () => import("@/view/pages/letters/scholarship-information/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Beasiswa"
                }
              },
              {
                path: "/letters/scholarship-information/detail/:id",
                name: "scholarship-information-detail",
                component: () => import("@/view/pages/letters/scholarship-information/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Beasiswa"
                }
              },
            ]
          },
          // allowance-information
          {
            path: "/letters/allowance-information",
            name: "allowance-information",
            redirect: "/letters/allowance-information/list",
            component: () => import("@/view/pages/letters/allowance-information/AllowanceInformation.vue"),
            meta: {
              title: 'Fisika UNJ | Surat Keterangan Tunjangan/BPJS'
            },
            children: [
              {
                path: "/letters/allowance-information/list",
                name: "allowance-information-list",
                component: () => import("@/view/pages/letters/allowance-information/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Tunjangan/BPJS"
                }
              },
              {
                path: "/letters/allowance-information/add",
                name: "allowance-information-add",
                component: () => import("@/view/pages/letters/allowance-information/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Tunjangan/BPJS"
                }
              },
              {
                path: "/letters/allowance-information/edit/:id",
                name: "allowance-information-edit",
                component: () => import("@/view/pages/letters/allowance-information/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Tunjangan/BPJS"
                }
              },
              {
                path: "/letters/allowance-information/detail/:id",
                name: "allowance-information-detail",
                component: () => import("@/view/pages/letters/allowance-information/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Keterangan Tunjangan/BPJS"
                }
              },
            ]
          },
          // transcript-request
          {
            path: "/letters/transcript-request",
            name: "transcript-request",
            redirect: "/letters/transcript-request/list",
            component: () => import("@/view/pages/letters/transcript-request/TranscriptRequest.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan PraTranskrip dan Transkrip",
            },
            children: [
              {
                path: "/letters/transcript-request/list",
                name: "transcript-request-list",
                component: () => import("@/view/pages/letters/transcript-request/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-request/add",
                name: "transcript-request-add",
                component: () => import("@/view/pages/letters/transcript-request/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-request/detail/:id",
                name: "transcript-request-detail",
                component: () => import("@/view/pages/letters/transcript-request/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-request/edit/:id",
                name: "transcript-request-edit",
                component: () => import("@/view/pages/letters/transcript-request/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan PraTranskrip dan Transkrip",
                },
              },
            ],
          },
          // space-loan-application
          {
            path: "/letters/space-loan-application",
            name: "space-loan-application",
            redirect: "/letters/space-loan-application/list",
            component: () => import("@/view/pages/letters/space-loan-application/SpaceLoanApplication.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Peminjaman Ruang",
            },
            children: [
              {
                path: "/letters/space-loan-application/list",
                name: "space-loan-application-list",
                component: () => import("@/view/pages/letters/space-loan-application/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Ruang",
                },
              },
              {
                path: "/letters/space-loan-application/add",
                name: "space-loan-application-add",
                component: () => import("@/view/pages/letters/space-loan-application/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Ruang",
                },
              },
              {
                path: "/letters/space-loan-application/detail/:id",
                name: "space-loan-application-detail",
                component: () => import("@/view/pages/letters/space-loan-application/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Ruang",
                },
              },
              {
                path: "/letters/space-loan-application/edit/:id",
                name: "space-loan-application-edit",
                component: () => import("@/view/pages/letters/space-loan-application/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Ruang",
                },
              },
            ],
          },
          // lab-loan-application
          {
            path: "/letters/lab-loan-application",
            name: "lab-loan-application",
            redirect: "/letters/lab-loan-application/list",
            component: () => import("@/view/pages/letters/lab-loan-application/LabLoanApplication.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pemakaian Lab",
            },
            children: [
              {
                path: "/letters/lab-loan-application/list",
                name: "lab-loan-application-list",
                component: () => import("@/view/pages/letters/lab-loan-application/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pemakaian Lab",
                },
              },
              {
                path: "/letters/lab-loan-application/add",
                name: "lab-loan-application-add",
                component: () => import("@/view/pages/letters/lab-loan-application/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pemakaian Lab",
                },
              },
              {
                path: "/letters/lab-loan-application/detail/:id",
                name: "lab-loan-application-detail",
                component: () => import("@/view/pages/letters/lab-loan-application/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pemakaian Lab",
                },
              },
              {
                path: "/letters/lab-loan-application/edit/:id",
                name: "lab-loan-application-edit",
                component: () => import("@/view/pages/letters/lab-loan-application/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pemakaian Lab",
                },
              },
            ],
          },
          // lab-tool-loan-application
          {
            path: "/letters/lab-tool-loan-application",
            name: "lab-tool-loan-application",
            redirect: "/letters/lab-tool-loan-application/list",
            component: () => import("@/view/pages/letters/lab-tool-loan-application/LabToolLoanApplication.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Lab",
            },
            children: [
              {
                path: "/letters/lab-tool-loan-application/list",
                name: "lab-tool-loan-application-list",
                component: () => import("@/view/pages/letters/lab-tool-loan-application/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Lab",
                },
              },
              {
                path: "/letters/lab-tool-loan-application/add",
                name: "lab-tool-loan-application-add",
                component: () => import("@/view/pages/letters/lab-tool-loan-application/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Lab",
                },
              },
              {
                path: "/letters/lab-tool-loan-application/detail/:id",
                name: "lab-tool-loan-application-detail",
                component: () => import("@/view/pages/letters/lab-tool-loan-application/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Lab",
                },
              },
              {
                path: "/letters/lab-tool-loan-application/edit/:id",
                name: "lab-tool-loan-application-edit",
                component: () => import("@/view/pages/letters/lab-tool-loan-application/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Peminjaman Alat Lab",
                },
              },
            ],
          },
          // transcript-remedial
          {
            path: "/letters/transcript-remedial",
            name: "transcript-remedial",
            redirect: "/letters/transcript-remedial/list",
            component: () => import("@/view/pages/letters/transcript-remedial/TranscriptRemedial.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Perbaikan PraTranskrip dan Transkrip",
            },
            children: [
              {
                path: "/letters/transcript-remedial/list",
                name: "transcript-remedial-list",
                component: () => import("@/view/pages/letters/transcript-remedial/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Perbaikan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-remedial/add",
                name: "transcript-remedial-add",
                component: () => import("@/view/pages/letters/transcript-remedial/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Perbaikan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-remedial/detail/:id",
                name: "transcript-remedial-detail",
                component: () => import("@/view/pages/letters/transcript-remedial/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Perbaikan PraTranskrip dan Transkrip",
                },
              },
              {
                path: "/letters/transcript-remedial/edit/:id",
                name: "transcript-remedial-edit",
                component: () => import("@/view/pages/letters/transcript-remedial/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Perbaikan PraTranskrip dan Transkrip",
                },
              },
            ],
          },
          // approval-request
          {
            path: "/letters/approval-request",
            name: "approval-request",
            redirect: "/letters/approval-request/list",
            component: () => import("@/view/pages/letters/approval-request/ApprovalRequest.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pengesahan DHS/KRS/KHS",
            },
            children: [
              {
                path: "/letters/approval-request/list",
                name: "approval-request-list",
                component: () => import("@/view/pages/letters/approval-request/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengesahan DHS/KRS/KHS",
                },
              },
              {
                path: "/letters/approval-request/add",
                name: "approval-request-add",
                component: () => import("@/view/pages/letters/approval-request/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengesahan DHS/KRS/KHS",
                },
              },
              {
                path: "/letters/approval-request/detail/:id",
                name: "approval-request-detail",
                component: () => import("@/view/pages/letters/approval-request/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengesahan DHS/KRS/KHS",
                },
              },
              {
                path: "/letters/approval-request/edit/:id",
                name: "approval-request-edit",
                component: () => import("@/view/pages/letters/approval-request/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pengesahan DHS/KRS/KHS",
                },
              },
            ],
          },
          // chemical-purchase
          {
            path: "/letters/chemical-purchase",
            name: "chemical-purchase",
            redirect: "/letters/chemical-purchase/list",
            component: () => import("@/view/pages/letters/chemical-purchase/ChemicalPurchase.vue"),
            meta: {
              title: "Fisika UNJ | Surat Permohonan Pembelian Bahan Kimia",
            },
            children: [
              {
                path: "/letters/chemical-purchase/list",
                name: "chemical-purchase-list",
                component: () => import("@/view/pages/letters/chemical-purchase/List.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pembelian Bahan Kimia",
                },
              },
              {
                path: "/letters/chemical-purchase/add",
                name: "chemical-purchase-add",
                component: () => import("@/view/pages/letters/chemical-purchase/Add.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pembelian Bahan Kimia",
                },
              },
              {
                path: "/letters/chemical-purchase/edit/:id",
                name: "chemical-purchase-edit",
                component: () => import("@/view/pages/letters/chemical-purchase/Edit.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pembelian Bahan Kimia",
                },
              },
              {
                path: "/letters/chemical-purchase/detail/:id",
                name: "chemical-purchase-detail",
                component: () => import("@/view/pages/letters/chemical-purchase/Detail.vue"),
                meta: {
                  title: "Fisika UNJ | Surat Permohonan Pembelian Bahan Kimia",
                },
              },
            ],
          },
        ],
      },

      // settings
      {
        path: "/settings",
        name: "settings",
        redirect: "/settings/edit/:id",
        component: () => import("@/view/pages/configuration/Configuration.vue"),
        meta: {
          title: "Fisika UNJ | Konfigurasi",
        },
        children: [
          {
            path: "/settings/edit/:id",
            name: "edit",
            component: () => import("@/view/pages/configuration/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Konfigurasi",
            },
          },
        ],
      },

      // ::masters::
      {
        path: "/masters",
        name: "masters",
        redirect: "/masters/settings",
        component: () => import("@/view/pages/masters/Masters.vue"),
        meta: {
          title: "Fisika UNJ | Master - Setting",
        },
        children: [
          // setting
          {
            path: "/masters/settings",
            name: "master-setting",
            component: () => import("@/view/pages/masters/Setting.vue"),
            meta: {
              title: "Fisika UNJ | Master - Setting",
            },
          },

          // access-roles
          {
            path: "/masters/user-managements/role-access",
            name: "master-access-roles",
            component: () => import("@/view/pages/masters/user-managements/RoleAccess.vue"),
            meta: {
              title: "Fisika UNJ | Master - Hak Akses Peran",
              access_right: "9031",
            },
          },

          // access-users
          {
            path: "/masters/user-managements/user-access",
            name: "master-access-users",
            component: () => import("@/view/pages/masters/user-managements/UserAccess.vue"),
            meta: {
              title: "Fisika UNJ | Master - Hak Akses User",
              access_right: "9033",
            },
          },

          // schools
          {
            path: "/masters/schools",
            name: "master-schools",
            component: () => import("@/view/pages/masters/schools/Schools.vue"),
            meta: {
              title: "Fisika UNJ | Master - Sekolah",
            },
          },
          {
            path: "/masters/schools/add",
            name: "master-schools-add",
            component: () => import("@/view/pages/masters/schools/SchoolsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Sekolah",
            },
          },
          {
            path: "/masters/schools/edit/:id",
            name: "master-schools-edit",
            component: () => import("@/view/pages/masters/schools/SchoolsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Sekolah",
            },
          },
          {
            path: "/masters/schools/detail/:id",
            name: "master-schools-detail",
            component: () => import("@/view/pages/masters/schools/SchoolsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Sekolah",
            },
          },
          {
            path: "/masters/schools/delete/:id",
            name: "master-schools-delete",
            component: () => import("@/view/pages/masters/schools/DeleteSchools.vue"),
            meta: {
              title: "Fisika UNJ | Master - Sekolah",
            },
          },

          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: "Fisika UNJ | Master - Role",
              access_right: "9027",
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Role",
              access_right: "9028",
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () => import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Peran",
              access_right: "9028",
            },
          },
          {
            path: "/masters/roles/detail/:id",
            name: "master-roles-detail",
            component: () => import("@/view/pages/masters/roles/RolesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Peran",
              access_right: "9027",
            },
          },
          {
            path: "/masters/roles/delete/:id",
            name: "master-roles-delete",
            component: () => import("@/view/pages/masters/roles/RolesDelete.vue"),
            meta: {
              title: "Fisika UNJ | Master - Peran",
              access_right: "9028",
            },
          },

          // users
          {
            path: "/masters/users",
            name: "master-users",
            component: () => import("@/view/pages/masters/users/Users.vue"),
            meta: {
              title: "Fisika UNJ | Master - User",
              access_right: "9001",
            },
          },
          // teachers
          {
            path: "/masters/users/teachers",
            name: "master-users-teachers-",
            component: () => import("@/view/pages/masters/users/Teachers.vue"),
            meta: {
              title: "Fisika UNJ | Dosen",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/teachers/add",
            name: "master-users-teachers-add",
            component: () => import("@/view/pages/masters/users/TeachersAdd.vue"),
            meta: {
              title: "Fisika UNJ | Dosen",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/teachers/edit/:id",
            name: "master-users-teachers-edit",
            component: () => import("@/view/pages/masters/users/TeachersUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Dosen",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/teachers/detail/:id",
            name: "master-users-teachers-detail",
            component: () => import("@/view/pages/masters/users/TeachersDetail.vue"),
            meta: {
              title: "Fisika UNJ | Dosen",
              access_right: "8001",
            },
          },
          // students
          {
            path: "/masters/users/students",
            name: "master-users-students-",
            component: () => import("@/view/pages/masters/users/Students.vue"),
            meta: {
              title: "Fisika UNJ | Mahasiswa",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/students/add",
            name: "master-users-students-add",
            component: () => import("@/view/pages/masters/users/StudentsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Mahasiswa",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/students/edit/:id",
            name: "master-users-students-edit",
            component: () => import("@/view/pages/masters/users/StudentsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Mahasiswa",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/students/detail/:id",
            name: "master-users-students-detail",
            component: () => import("@/view/pages/masters/users/StudentsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Mahasiswa",
              access_right: "8001",
            },
          },
          // laboran
          {
            path: "/masters/users/laboran",
            name: "master-users-laboran",
            component: () => import("@/view/pages/masters/users/Laboran.vue"),
            meta: {
              title: "Fisika UNJ | Laboran",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/laboran/add",
            name: "master-users-laboran-add",
            component: () => import("@/view/pages/masters/users/LaboranAdd.vue"),
            meta: {
              title: "Fisika UNJ | Laboran",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/laboran/edit/:id",
            name: "master-users-laboran-edit",
            component: () => import("@/view/pages/masters/users/LaboranUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Laboran",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/laboran/detail/:id",
            name: "master-users-laboran-detail",
            component: () => import("@/view/pages/masters/users/LaboranDetail.vue"),
            meta: {
              title: "Fisika UNJ | Laboran",
              access_right: "8001",
            },
          },
          // admin-korprodi
          {
            path: "/masters/users/admin-korprodi",
            name: "master-users-admin-korprodi",
            component: () => import("@/view/pages/masters/users/AdminKorprodi.vue"),
            meta: {
              title: "Fisika UNJ | Admin Korprodi",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/admin-korprodi/add",
            name: "master-users-admin-korprodi-add",
            component: () => import("@/view/pages/masters/users/AdminKorprodiAdd.vue"),
            meta: {
              title: "Fisika UNJ | Admin Korprodi",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/admin-korprodi/edit/:id",
            name: "master-users-admin-korprodi-edit",
            component: () => import("@/view/pages/masters/users/AdminKorprodiUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Admin Korprodi",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/admin-korprodi/detail/:id",
            name: "master-users-admin-korprodi-detail",
            component: () => import("@/view/pages/masters/users/AdminKorprodiDetail.vue"),
            meta: {
              title: "Fisika UNJ | Admin Korprodi",
              access_right: "8001",
            },
          },
          // laboratory-head
          {
            path: "/masters/users/laboratory-head",
            name: "master-users-laboratory-head",
            component: () => import("@/view/pages/masters/users/LaboratoryHead.vue"),
            meta: {
              title: "Fisika UNJ | Admin Kepala Lab",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/laboratory-head/add",
            name: "master-users-laboratory-head-add",
            component: () => import("@/view/pages/masters/users/LaboratoryHeadAdd.vue"),
            meta: {
              title: "Fisika UNJ | Admin Kepala Lab",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/laboratory-head/edit/:id",
            name: "master-users-laboratory-head-edit",
            component: () => import("@/view/pages/masters/users/LaboratoryHeadUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Admin Kepala Lab",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/laboratory-head/detail/:id",
            name: "master-users-laboratory-head-detail",
            component: () => import("@/view/pages/masters/users/LaboratoryHeadDetail.vue"),
            meta: {
              title: "Fisika UNJ | Admin Kepala Lab",
              access_right: "8001",
            },
          },
          // admin-tu-prodi
          {
            path: "/masters/users/admin-tu-prodi",
            name: "master-users-admin-tu-prodi",
            component: () => import("@/view/pages/masters/users/AdminTUProdi.vue"),
            meta: {
              title: "Fisika UNJ | Admin TU Prodi",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/admin-tu-prodi/add",
            name: "master-users-admin-tu-prodi-add",
            component: () => import("@/view/pages/masters/users/AdminTUProdiAdd.vue"),
            meta: {
              title: "Fisika UNJ | Admin TU Prodi",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/admin-tu-prodi/edit/:id",
            name: "master-users-admin-tu-prodi-edit",
            component: () => import("@/view/pages/masters/users/AdminTUProdiUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Admin TU Prodi",
              access_right: "8002",
            },
          },
          {
            path: "/masters/users/admin-tu-prodi/detail/:id",
            name: "master-users-admin-tu-prodi-detail",
            component: () => import("@/view/pages/masters/users/AdminTUProdiDetail.vue"),
            meta: {
              title: "Fisika UNJ | Admin TU Prodi",
              access_right: "8001",
            },
          },
          {
            path: "/masters/users/add",
            name: "master-users-add",
            component: () => import("@/view/pages/masters/users/UsersAdd.vue"),
            meta: {
              title: "Fisika UNJ | User",
              access_right: "9002",
            },
          },
          {
            path: "/masters/users/detail/:id",
            name: "master-users-detail",
            component: () => import("@/view/pages/masters/users/UsersDetail.vue"),
            meta: {
              title: "Fisika UNJ | User",
              access_right: "9001",
            },
          },
          {
            path: "/masters/users/edit/:id",
            name: "master-users-edit",
            component: () => import("@/view/pages/masters/users/UsersUpdate.vue"),
            meta: {
              title: "Fisika UNJ | User",
              access_right: "9002",
            },
          },
          {
            path: "/masters/users/delete/:id",
            name: "master-users-delete",
            component: () => import("@/view/pages/masters/users/UsersDelete.vue"),
            meta: {
              title: "Fisika UNJ | User",
              access_right: "9002",
            },
          },

          // collections
          {
            path: "/masters/collections",
            name: "master-collections",
            component: () => import("@/view/pages/masters/collections/Collections.vue"),
            meta: {
              title: "Fisika UNJ | Master - Collection",
              access_right: "9003",
            },
          },
          {
            path: "/masters/collections/add",
            name: "master-collections-add",
            component: () => import("@/view/pages/masters/collections/CollectionsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Collection",
              access_right: "9004",
            },
          },
          {
            path: "/masters/collections/edit/:id",
            name: "master-collections-edit",
            component: () => import("@/view/pages/masters/collections/CollectionsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Collection",
              access_right: "9004",
            },
          },
          {
            path: "/masters/collections/detail/:id",
            name: "master-collections-detail",
            component: () => import("@/view/pages/masters/collections/CollectionsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Collection",
              access_right: "9003",
            },
          },

          // formats
          {
            path: "/masters/formats",
            name: "master-formats",
            component: () => import("@/view/pages/masters/formats/Formats.vue"),
            meta: {
              title: "Fisika UNJ | Master - Format",
              access_right: "9005",
            },
          },
          {
            path: "/masters/formats/add",
            name: "master-formats-add",
            component: () => import("@/view/pages/masters/formats/FormatsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Format",
              access_right: "9006",
            },
          },
          {
            path: "/masters/formats/detail/:id",
            name: "master-formats-detail",
            component: () => import("@/view/pages/masters/formats/FormatsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Format",
              access_right: "9005",
            },
          },
          {
            path: "/masters/formats/edit/:id",
            name: "master-formats-edit",
            component: () => import("@/view/pages/masters/formats/FormatsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Format",
              access_right: "9006",
            },
          },
          {
            path: "/masters/formats/delete/:id",
            name: "master-formats-delete",
            component: () => import("@/view/pages/masters/formats/FormatsDelete.vue"),
            meta: {
              title: "Fisika UNJ | Master - Format",
              access_right: "9006",
            },
          },

          // categories
          {
            path: "/masters/categories",
            name: "master-categories",
            component: () => import("@/view/pages/masters/categories/Categories.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori",
              access_right: "9007",
            },
          },
          {
            path: "/masters/categories/add",
            name: "master-categories-add",
            component: () => import("@/view/pages/masters/categories/CategoriesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori",
              access_right: "9008",
            },
          },
          {
            path: "/masters/categories/edit/:id",
            name: "master-categories-edit",
            component: () => import("@/view/pages/masters/categories/CategoriesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori",
              access_right: "9008",
            },
          },
          {
            path: "/masters/categories/delete/:id",
            name: "master-categories-delete",
            component: () => import("@/view/pages/masters/categories/CategoriesDelete.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori",
              access_right: "9008",
            },
          },
          {
            path: "/masters/categories/detail/:id",
            name: "master-categories-detail",
            component: () => import("@/view/pages/masters/categories/CategoriesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori",
              access_right: "9007",
            },
          },

          // classworks-types
          {
            path: "/masters/classwork-types",
            name: "master-classwork-types",
            component: () => import("@/view/pages/masters/classwork_types/NewClassworkTypes.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Tugas",
              access_right: "9019",
            },
          },
          {
            path: "/masters/classwork-types/detail/:id",
            name: "master-classwork-types-detail",
            component: () => import("@/view/pages/masters/classwork_types/NewClassworkTypesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Tugas",
              access_right: "9019",
            },
          },

          // duration-units
          {
            path: "/masters/duration-units",
            name: "master-duration-types",
            component: () => import("@/view/pages/masters/duration_units/NewDurationUnits.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan Durasi",
              access_right: "9023",
            },
          },
          {
            path: "/masters/duration-units/add",
            name: "master-duration-types-add",
            component: () => import("@/view/pages/masters/duration_units/NewDurationUnitsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan Durasi",
              access_right: "9024",
            },
          },
          {
            path: "/masters/duration-units/edit/:id",
            name: "master-duration-types-edit",
            component: () => import("@/view/pages/masters/duration_units/NewDurationUnitsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan Durasi",
              access_right: "9024",
            },
          },
          {
            path: "/masters/duration-units/detail/:id",
            name: "master-duration-types-detail",
            component: () => import("@/view/pages/masters/duration_units/NewDurationUnitsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan Durasi",
              access_right: "9023",
            },
          },

          // content-types
          {
            path: "/masters/content-types",
            name: "master-content-types",
            component: () => import("@/view/pages/masters/content_types/NewContentTypes.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Konten",
              access_right: "9021",
            },
          },
          {
            path: "/masters/content-types/add",
            name: "master-content-types-add",
            component: () => import("@/view/pages/masters/content_types/NewContentTypesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Konten",
              access_right: "9022",
            },
          },
          {
            path: "/masters/content-types/edit/:id",
            name: "master-content-types-edit",
            component: () => import("@/view/pages/masters/content_types/NewContentTypesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Konten",
              access_right: "9022",
            },
          },
          {
            path: "/masters/content-types/detail/:id",
            name: "master-content-types-detail",
            component: () => import("@/view/pages/masters/content_types/NewContentTypesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Konten",
              access_right: "9021",
            },
          },

          // presence-types
          {
            path: "/masters/presence-types",
            name: "master-presence-types",
            component: () => import("@/view/pages/masters/presence_types/NewPresenceTypes.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Absen",
              access_right: "9009",
            },
          },
          {
            path: "/masters/presence-types/add",
            name: "master-presence-types-add",
            component: () => import("@/view/pages/masters/presence_types/PresenceTypesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Absen",
              access_right: "9010",
            },
          },
          {
            path: "/masters/presence-types/detail/:id",
            name: "master-presence-types-detail",
            component: () => import("@/view/pages/masters/presence_types/PresenceTypesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Absen",
              access_right: "9009",
            },
          },
          {
            path: "/masters/presence-types/edit/:id",
            name: "master-presence-types-edit",
            component: () => import("@/view/pages/masters/presence_types/PresenceTypesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Absen",
              access_right: "9010",
            },
          },

          // meeting platform
          // {
          //   path: "/masters/meeting-platforms",
          //   name: "master-presence-platforms",
          //   component: () => import("@/view/pages/masters/meeting-platforms/MeetingPlatforms.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Platform Pertemuan'
          //   },
          // },
          {
            path: "/masters/meeting-platforms",
            name: "master-presence-platforms",
            component: () => import("@/view/pages/masters/meeting-platforms/NewMeetingPlatforms.vue"),
            meta: {
              title: "Fisika UNJ | Master - Platform Pertemuan",
              access_right: "9011",
            },
          },
          {
            path: "/masters/meeting-platforms/add",
            name: "master-presence-platforms-add",
            component: () => import("@/view/pages/masters/meeting-platforms/NewMeetingPlatformsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Platform Pertemuan",
              access_right: "9012",
            },
          },
          {
            path: "/masters/meeting-platforms/edit/:id",
            name: "master-presence-platforms-edit",
            component: () => import("@/view/pages/masters/meeting-platforms/NewMeetingPlatformsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Platform Pertemuan",
              access_right: "9012",
            },
          },
          {
            path: "/masters/meeting-platforms/detail/:id",
            name: "master-presence-platforms-detail",
            component: () => import("@/view/pages/masters/meeting-platforms/NewMeetingPlatformsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Platform Pertemuan",
              access_right: "9011",
            },
          },

          // section
          {
            path: "/masters/sections",
            name: "master-seksi",
            component: () => import("@/view/pages/masters/sections/Sections.vue"),
            meta: {
              title: "Fisika UNJ | Master - Seksi",
            },
          },

          // question-types
          {
            path: "/masters/question-types",
            name: "master-question-types",
            component: () => import("@/view/pages/masters/question-types/NewQuestionTypes.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Pertanyaan",
              access_right: "9025",
            },
          },
          {
            path: "/masters/question-types/add",
            name: "master-question-types-add",
            component: () => import("@/view/pages/masters/question-types/NewQuestionTypesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Pertanyaan",
              access_right: "9026",
            },
          },
          {
            path: "/masters/question-types/edit/:id",
            name: "master-question-types-edit",
            component: () => import("@/view/pages/masters/question-types/NewQuestionTypesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Pertanyaan",
              access_right: "9026",
            },
          },
          {
            path: "/masters/question-types/detail/:id",
            name: "master-question-types-detail",
            component: () => import("@/view/pages/masters/question-types/NewQuestionTypesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Pertanyaan",
              access_right: "9025",
            },
          },

          // topics
          // {
          //   path: "/masters/topics",
          //   name: "master-topics",
          //   component: () => import("@/view/pages/masters/topics/NewTopics.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Topik'
          //   },
          // },
          // {
          //   path: "/masters/topics/add",
          //   name: "master-topics-add",
          //   component: () => import("@/view/pages/masters/topics/TopicsAdd.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Topik'
          //   },
          // },
          // {
          //   path: "/masters/topics/update/:id",
          //   name: "master-topics-update",
          //   component: () => import("@/view/pages/masters/topics/TopicsUpdate.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Topik'
          //   },
          // },
          // {
          //   path: "/masters/topics/detail/:id",
          //   name: "master-topics-detail",
          //   component: () => import("@/view/pages/masters/topics/TopicsDetail.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Topik'
          //   },
          // },

          // competence-types
          {
            path: "/masters/competence-types",
            name: "master-competence-types",
            component: () => import("@/view/pages/masters/competence-types/CompetenceType.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Kompetensi",
            },
          },
          {
            path: "/masters/competence-types/add",
            name: "master-competence-types-add",
            component: () => import("@/view/pages/masters/competence-types/CompetenceTypeAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Kompetensi",
            },
          },
          {
            path: "/masters/competence-types/edit/:id",
            name: "master-competence-types-edit",
            component: () => import("@/view/pages/masters/competence-types/CompetenceTypeUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Kompetensi",
            },
          },
          {
            path: "/masters/competence-types/detail/:id",
            name: "master-competence-types-detail",
            component: () => import("@/view/pages/masters/competence-types/CompetenceTypeDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Kompetensi",
            },
          },

          // competence-standards
          {
            path: "/masters/competence-standards",
            name: "master-competence-standards",
            component: () => import("@/view/pages/masters/competence-standards/CompetenceStandard.vue"),
            meta: {
              title: "Fisika UNJ | Master - Standar Kompetensi",
              access_right: "8005",
            },
          },
          {
            path: "/masters/competence-standards/add",
            name: "master-competence-standards-add",
            component: () => import("@/view/pages/masters/competence-standards/CompetenceStandardAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Standar Kompetensi",
              access_right: "8006",
            },
          },
          {
            path: "/masters/competence-standards/edit/:id",
            name: "master-competence-standards-edit",
            component: () => import("@/view/pages/masters/competence-standards/CompetenceStandardUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Standar Kompetensi",
              access_right: "8006",
            },
          },
          {
            path: "/masters/competence-standards/detail/:id",
            name: "master-competence-standards-detail",
            component: () => import("@/view/pages/masters/competence-standards/CompetenceStandardDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Standar Kompetensi",
              access_right: "8005",
            },
          },

          // majors
          {
            path: "/masters/majors",
            name: "master-majors",
            component: () => import("@/view/pages/masters/majors/Majors.vue"),
            meta: {
              title: "Fisika UNJ | Master - Prodi",
              access_right: "8015",
            },
          },
          {
            path: "/masters/majors/add",
            name: "master-majors-add",
            component: () => import("@/view/pages/masters/majors/MajorsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Prodi",
              access_right: "8016",
            },
          },
          {
            path: "/masters/majors/edit/:id",
            name: "master-majors-edit",
            component: () => import("@/view/pages/masters/majors/MajorsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Prodi",
              access_right: "8016",
            },
          },
          {
            path: "/masters/majors/detail/:id",
            name: "master-majors-detail",
            component: () => import("@/view/pages/masters/majors/MajorsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Prodi",
              access_right: "8015",
            },
          },
          // school-years
          {
            path: "/masters/school-years",
            name: "master-school-years",
            component: () => import("@/view/pages/masters/school-years/SchoolYears.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tahun Ajaran",
              access_right: "8013",
            },
          },
          {
            path: "/masters/school-years/add",
            name: "master-school-years-add",
            component: () => import("@/view/pages/masters/school-years/SchoolYearsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tahun Ajaran",
              access_right: "8014",
            },
          },
          {
            path: "/masters/school-years/edit/:id",
            name: "master-school-years-edit",
            component: () => import("@/view/pages/masters/school-years/SchoolYearsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tahun Ajaran",
              access_right: "8014",
            },
          },
          {
            path: "/masters/school-years/detail/:id",
            name: "master-school-years-detail",
            component: () => import("@/view/pages/masters/school-years/SchoolYearsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tahun Ajaran",
              access_right: "8013",
            },
          },
          // announcement-categories
          {
            path: "/masters/announcement-categories",
            name: "master-announcement-categories",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategory.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori Pengumuman",
              access_right: "9013",
            },
          },
          {
            path: "/masters/announcement-categories/add",
            name: "master-announcement-categories-add",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori Pengumuman",
              access_right: "9014",
            },
          },
          {
            path: "/masters/announcement-categories/edit/:id",
            name: "master-announcement-categories-edit",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori Pengumuman",
              access_right: "9014",
            },
          },
          {
            path: "/masters/announcement-categories/detail/:id",
            name: "master-announcement-categories-detail",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kategori Pengumuman",
              access_right: "9013",
            },
          },
          // announcements
          {
            path: "/masters/announcements",
            name: "master-announcements",
            component: () => import("@/view/pages/masters/announcements/Announcement.vue"),
            meta: {
              title: "Fisika UNJ | Master - Pengumuman",
              access_right: "8009",
            },
          },
          {
            path: "/masters/announcements/add",
            name: "master-announcements-add",
            component: () => import("@/view/pages/masters/announcements/AnnouncementAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Pengumuman",
              access_right: "8010",
            },
          },
          {
            path: "/masters/announcements/edit/:id",
            name: "master-announcements-edit",
            component: () => import("@/view/pages/masters/announcements/AnnouncementUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Pengumuman",
              access_right: "8010",
            },
          },
          {
            path: "/masters/announcements/detail/:id",
            name: "master-announcements-detail",
            component: () => import("@/view/pages/masters/announcements/AnnouncementDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Pengumuman",
              access_right: "8009",
            },
          },
          // periode-types
          {
            path: "/masters/periode-types",
            name: "master-periode-types",
            component: () => import("@/view/pages/masters/periode-types/PeriodeType.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Periode",
              access_right: "9015",
            },
          },
          {
            path: "/masters/periode-types/add",
            name: "master-periode-types-add",
            component: () => import("@/view/pages/masters/periode-types/PeriodeTypeAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Periode",
              access_right: "9016",
            },
          },
          {
            path: "/masters/periode-types/edit/:id",
            name: "master-periode-types-edit",
            component: () => import("@/view/pages/masters/periode-types/PeriodeTypeUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Periode",
              access_right: "9016",
            },
          },
          {
            path: "/masters/periode-types/detail/:id",
            name: "master-periode-types-detail",
            component: () => import("@/view/pages/masters/periode-types/PeriodeTypeDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jenis Periode",
              access_right: "9015",
            },
          },
          // uom
          {
            path: "/masters/uom/list",
            name: "master-uom",
            component: () => import("@/view/pages/masters/uom/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan",
              access_right: "9015",
            },
          },
          {
            path: "/masters/uom/add",
            name: "master-uom-add",
            component: () => import("@/view/pages/masters/uom/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan",
              access_right: "9016",
            },
          },
          {
            path: "/masters/uom/edit/:id",
            name: "master-uom-edit",
            component: () => import("@/view/pages/masters/uom/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Satuan",
              access_right: "9016",
            },
          },
          // {
          //   path: "/masters/uom/detail/:id",
          //   name: "master-uom-detail",
          //   component: () => import("@/view/pages/masters/uom/Detail.vue"),
          //   meta: {
          //     title: 'Fisika UNJ | Master - Satuan',
          //     access_right: '9015'
          //   },
          // },
          // buildings
          {
            path: "/masters/buildings/list",
            name: "master-buildings",
            component: () => import("@/view/pages/masters/buildings/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Gedung",
              access_right: "9015",
            },
          },
          {
            path: "/masters/buildings/add",
            name: "master-buildings-add",
            component: () => import("@/view/pages/masters/buildings/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Gedung",
              access_right: "9016",
            },
          },
          {
            path: "/masters/buildings/edit/:id",
            name: "master-buildings-edit",
            component: () => import("@/view/pages/masters/buildings/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Gedung",
              access_right: "9016",
            },
          },
          {
            path: "/masters/buildings/detail/:id",
            name: "master-buildings-detail",
            component: () => import("@/view/pages/masters/buildings/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Gedung",
              access_right: "9015",
            },
          },
          // rooms
          {
            path: "/masters/rooms/list",
            name: "master-rooms",
            component: () => import("@/view/pages/masters/rooms/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Ruang",
              access_right: "9015",
            },
          },
          {
            path: "/masters/rooms/add",
            name: "master-rooms-add",
            component: () => import("@/view/pages/masters/rooms/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Ruang",
              access_right: "9016",
            },
          },
          {
            path: "/masters/rooms/edit/:id",
            name: "master-rooms-edit",
            component: () => import("@/view/pages/masters/rooms/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Ruang",
              access_right: "9016",
            },
          },
          {
            path: "/masters/rooms/detail/:id",
            name: "master-rooms-detail",
            component: () => import("@/view/pages/masters/rooms/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Ruang",
              access_right: "9015",
            },
          },
          {
            path: "/masters/rooms/calendar",
            name: "master-rooms-calendar",
            component: () => import("@/view/pages/masters/rooms/Calendar.vue"),
            meta: {
              title: "Fisika UNJ | Kalendar",
              access_right: "9015",
            },
          },
          // times
          {
            path: "/masters/times/list",
            name: "master-times",
            component: () => import("@/view/pages/masters/times/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jam",
              access_right: "9015",
            },
          },
          {
            path: "/masters/times/add",
            name: "master-times-add",
            component: () => import("@/view/pages/masters/times/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jam",
              access_right: "9016",
            },
          },
          {
            path: "/masters/times/edit/:id",
            name: "master-times-edit",
            component: () => import("@/view/pages/masters/times/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jam",
              access_right: "9016",
            },
          },
          {
            path: "/masters/times/detail/:id",
            name: "master-times-detail",
            component: () => import("@/view/pages/masters/times/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Jam",
              access_right: "9015",
            },
          },
          // study-programs
          {
            path: "/masters/study-programs/list",
            name: "master-study-programs",
            component: () => import("@/view/pages/masters/study-programs/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Program Studi",
              access_right: "9015",
            },
          },
          {
            path: "/masters/study-programs/add",
            name: "master-study-programs-add",
            component: () => import("@/view/pages/masters/study-programs/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Program Studi",
              access_right: "9016",
            },
          },
          {
            path: "/masters/study-programs/edit/:id",
            name: "master-study-programs-edit",
            component: () => import("@/view/pages/masters/study-programs/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Program Studi",
              access_right: "9016",
            },
          },
          {
            path: "/masters/study-programs/detail/:id",
            name: "master-study-programs-detail",
            component: () => import("@/view/pages/masters/study-programs/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Program Studi",
              access_right: "9015",
            },
          },
          // subjects
          {
            path: "/masters/subjects/list",
            name: "master-subjects",
            component: () => import("@/view/pages/masters/subjects/List.vue"),
            meta: {
              title: "Fisika UNJ | Master - Mata Kuliah",
              access_right: "9015",
            },
          },
          {
            path: "/masters/subjects/add",
            name: "master-subjects-add",
            component: () => import("@/view/pages/masters/subjects/Add.vue"),
            meta: {
              title: "Fisika UNJ | Master - Mata Kuliah",
              access_right: "9016",
            },
          },
          {
            path: "/masters/subjects/edit/:id",
            name: "master-subjects-edit",
            component: () => import("@/view/pages/masters/subjects/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Master - Mata Kuliah",
              access_right: "9016",
            },
          },
          {
            path: "/masters/subjects/detail/:id",
            name: "master-subjects-detail",
            component: () => import("@/view/pages/masters/subjects/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Mata Kuliah",
              access_right: "9015",
            },
          },
          // minimum-completeness-criterias
          {
            path: "/masters/minimum-completeness-criterias",
            name: "master-minimum-completeness-criterias",
            component: () => import("@/view/pages/masters/minimum-completeness-criterias/MinimumCompletenessCriteria.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kriteria Ketuntasan Minimal(KKM)",
              access_right: "8011",
            },
          },
          // {
          //   path: "/masters/minimum-completeness-criterias/add",
          //   name: "master-minimum-completeness-criterias-add",
          //   component: () => import("@/view/pages/masters/minimum-completeness-criterias/MinimumCompletenessCriteriaAdd.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Kriteria Ketuntasan Minimal(KKM)'
          //   },
          // },
          // {
          //   path: "/masters/minimum-completeness-criterias/edit/:id",
          //   name: "master-minimum-completeness-criterias-edit",
          //   component: () => import("@/view/pages/masters/minimum-completeness-criterias/MinimumCompletenessCriteriaUpdate.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Kriteria Ketuntasan Minimal(KKM)'
          //   },
          // },
          // {
          //   path: "/masters/minimum-completeness-criterias/detail/:id",
          //   name: "master-minimum-completeness-criterias-detail",
          //   component: () => import("@/view/pages/masters/minimum-completeness-criterias/MinimumCompletenessCriteriaDetail.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Kriteria Ketuntasan Minimal(KKM)'
          //   },
          // },

          // class-groups
          {
            path: "/masters/class-groups",
            name: "master-class-groups",
            component: () => import("@/view/pages/masters/class-groups/ClassGroups.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kelompok Kelas",
              access_right: "8003",
            },
          },
          {
            path: "/masters/class-groups/add",
            name: "master-class-groups-add",
            component: () => import("@/view/pages/masters/class-groups/ClassGroupsAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kelompok Kelas",
              access_right: "8004",
            },
          },
          {
            path: "/masters/class-groups/edit/:id",
            name: "master-class-groups-edit",
            component: () => import("@/view/pages/masters/class-groups/ClassGroupsUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kelompok Kelas",
              access_right: "8004",
            },
          },
          {
            path: "/masters/class-groups/detail/:classGroupid/:teacherId",
            name: "master-class-groups-detail",
            component: () => import("@/view/pages/masters/class-groups/ClassGroupsDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kelompok Kelas",
              access_right: "8003",
            },
          },
          {
            path: "/masters/class-groups/sub-detail/:classGroupid/:teacherId",
            name: "master-class-groups-sub-detail",
            component: () => import("@/view/pages/masters/class-groups/Detail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Kelompok Kelas",
              access_right: "8003",
            },
          },

          // grade
          {
            path: "/masters/grades",
            name: "master-grades",
            component: () => import("@/view/pages/masters/grades/Grades.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tingkat",
              access_right: "8017",
            },
          },
          {
            path: "/masters/grades/add",
            name: "master-grades-add",
            component: () => import("@/view/pages/masters/grades/GradesAdd.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tingkat",
              access_right: "8018",
            },
          },
          {
            path: "/masters/grades/edit/:id",
            name: "master-grades-edit",
            component: () => import("@/view/pages/masters/grades/GradesUpdate.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tingkat",
              access_right: "8018",
            },
          },
          {
            path: "/masters/grades/delete/:id",
            name: "master-grades-delete",
            component: () => import("@/view/pages/masters/grades/GradesDelete.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tingkat",
              access_right: "8018",
            },
          },
          {
            path: "/masters/grades/detail/:id",
            name: "master-grades-detail",
            component: () => import("@/view/pages/masters/grades/GradesDetail.vue"),
            meta: {
              title: "Fisika UNJ | Master - Tingkat",
              access_right: "8017",
            },
          },

          // semesters
          {
            path: "/masters/semesters",
            name: "master-semesters",
            component: () => import("@/view/pages/masters/semesters/List.vue"),
            meta: {
              title: "Fisika UNJ | Semester",
              access_right: "8017",
            },
          },
          {
            path: "/masters/semesters/add",
            name: "master-semesters-add",
            component: () => import("@/view/pages/masters/semesters/Add.vue"),
            meta: {
              title: "Fisika UNJ | Semester",
              access_right: "8018",
            },
          },
          {
            path: "/masters/semesters/edit/:id",
            name: "master-semesters-edit",
            component: () => import("@/view/pages/masters/semesters/Edit.vue"),
            meta: {
              title: "Fisika UNJ | Semester",
              access_right: "8018",
            },
          },
          // {
          //   path: "/masters/semesters/detail/:id",
          //   name: "master-semesters-detail",
          //   component: () => import("@/view/pages/masters/semesters/Detail.vue"),
          //   meta:{
          //     title: 'Fisika UNJ | Master - Tingkat',
          //     access_right: '8017'
          //   },
          // },
        ],
      },

      // blankboard
      {
        path: "/blankboard",
        name: "blankboard",
        component: () => import("@/view/pages/Blankboard.vue"),
        meta: {
          title: "Blankboard",
        },
      },
      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/custom_pages/Profile.vue"),
        meta: {
          title: "Fisika UNJ | Profil",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: "Fisika UNJ",
        },
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: "Fisika UNJ - Register",
        },
      },
    ],
  },
  // redirect to 404
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      title: "Fisika UNJ | Page Not Found",
    },
    component: () => import("@/view/pages/error/Error-1.vue"),
  },

  // 503
  {
    path: "/access-forbidden",
    name: "access-forbidden",
    meta: {
      title: "Fisika UNJ | Access Forbidden",
    },
    component: () => import("@/view/pages/error/Error-7.vue"),
  },

  // Grafic
  {
    path: "/graph-report",
    name: "graph-report",
    meta: {
      title: "Fisika UNJ | Laporan Grafik",
    },
    component: () => import("@/view/pages/graph-report/GraphReport.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route Middleware
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.access_right)) {
    let localStorageAccessRight = JSON.parse(localStorage.getItem("access_right_display"));
    // //('localStorageAccessRight', localStorageAccessRight)
    if (localStorageAccessRight != null) {
      let result = false;
      for (let i = 0; i < localStorageAccessRight.length; i++) {
        result = false;
        if (localStorageAccessRight[i] == to.meta.access_right) {
          result = true;
          break;
        }
      }
      if (result) {
        next();
      } else {
        next("/access-forbidden");
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
